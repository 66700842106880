
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .text {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
  
  .grid {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .grid-item {
    flex: 1;
  }

  .player {
    width: 100%;
    max-width: 560px; /* Ajusta el ancho máximo del video */
    height: auto;
    margin: 0 auto; /* Centra el video horizontalmente */
    border: 2px solid  #384E21;
    border-radius: 10px;
    display: block; /* Asegura que el elemento se comporte como un bloque */
  }
  // Estilos para tabletas
@media (max-width: 992px) {
  .container {
    max-width: 90%; // Reduce el ancho máximo para tabletas
    padding: 15px; // Ajusta el padding
  }

  .title {
    font-size: 1.8em; // Reduce el tamaño de la fuente para tabletas
    margin-bottom: 15px; // Reduce el margen inferior
  }

  .text {
    font-size: 1em; // Reduce el tamaño de la fuente para tabletas
    margin-bottom: 25px; // Ajusta el margen inferior
  }

  .grid {
    flex-direction: column; // Cambia la dirección del flex a columna para apilar los elementos verticalmente en tabletas
    gap: 15px; // Reduce el espacio entre los elementos de la grilla
    margin-bottom: 30px; // Ajusta el margen inferior
  }

  .player {
    max-width: 100%; // Ajusta el video para que ocupe todo el ancho disponible
    margin: 0 auto; // Mantiene el centrado del video
    border-width: 1px; // Reduce el grosor del borde
  }
}

// Estilos para móviles
@media (max-width: 768px) {
  .container {
    max-width: 100%; // Usa todo el ancho disponible en móviles
    padding: 10px; // Ajusta el padding para pantallas pequeñas
  }

  .title {
    font-size: 1.5em; // Reduce aún más el tamaño de la fuente para móviles
    margin-bottom: 10px; // Ajusta el margen inferior
  }

  .text {
    font-size: 0.9em; // Reduce el tamaño de la fuente para móviles
    margin-bottom: 20px; // Ajusta el margen inferior
  }

  .grid {
    flex-direction: column; // Apila los elementos verticalmente
    gap: 20px; // Reduce aún más el espacio entre los elementos
    margin-bottom: 10px; // Reduce el margen inferior
  }

  .grid-item {
    width: 100%; // Asegura que cada elemento de la grilla ocupe todo el ancho disponible
  }

  .player {
    max-width: 100%; // Ajusta el video para que ocupe todo el ancho disponible
    margin: 0 auto; // Mantiene el centrado del video
    border-width: 1px; // Mantiene el grosor del borde reducido
  }
}
