
.image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto; /* Centra la sección en la página */
  }
  
  .image-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .image-item img {
    max-width: 48%; /* Ancho máximo de las imágenes */
    height: auto;
  }
  
  /* Estilos para alternar la alineación de las imágenes */
  .image-item:nth-child(even) img {
    order: 1; /* Cambia el orden de las imágenes en filas pares */
  }
  
  .image-item:nth-child(odd) img {
    order: -1; /* Cambia el orden de las imágenes en filas impares */
  }
  
  /* Estilos para el texto */
  .text-wrapper {
    max-width: 800px;
    margin: 0 auto; /* Centra el texto en la página */
    text-align: center;
   
  }
  
  .text-wrapper h2,
  .text-wrapper p {
    margin-bottom: 20px; 
 
  }
  p{
    margin-left: 35px;
    margin-right: 35px;
    text-align: justify;
  }
   // Estilos para tabletas
@media (max-width: 992px) {
  .image-section {
    max-width: 90%; // Reduce el ancho máximo para tabletas
  }

  .image-item {
    flex-direction: column; // Cambia la dirección del flex a columna para apilar las imágenes verticalmente
    align-items: center; // Alinea el contenido al centro
    padding: 10px; // Reduce el padding para tabletas
    margin-bottom: 15px; // Reduce el margen inferior
  }

  .image-item img {
    max-width: 80%; // Reduce el ancho máximo de las imágenes
    margin-bottom: 10px; // Añade un margen inferior entre las imágenes y el texto
  }

  .text-wrapper {
    max-width: 90%; // Reduce el ancho máximo del contenedor de texto
  }

  .text-wrapper h2,
  .text-wrapper p {
    margin-bottom: 15px; // Reduce el margen inferior
    font-size: 1.2em; // Ajusta el tamaño de la fuente para tabletas
  }

  p {
    margin-left: 20px; // Reduce los márgenes laterales
    margin-right: 20px;
    text-align: justify;
  }
}

// Estilos para móviles
@media (max-width: 768px) {
  .image-section {
    max-width: 100%; // Usa todo el ancho disponible en móviles
    padding: 0 10px; // Añade padding lateral para dar espacio a los bordes
  }

  .image-item {
    padding: 5px; // Reduce el padding
    margin-bottom: 10px; // Reduce el margen inferior entre las secciones de imagen
  }

  .image-item img {
    max-width: 100%; // Las imágenes ocupan todo el ancho del contenedor
    height: auto; // Mantiene la proporción de las imágenes
    margin-bottom: 5px; // Añade un pequeño margen inferior entre imágenes y texto
  }

  .text-wrapper {
    max-width: 100%; // Usa todo el ancho disponible para el texto
    padding: 0 10px; // Añade padding lateral
  }

  .text-wrapper h2,
  .text-wrapper p {
    margin-bottom: 10px; // Reduce el margen inferior
    font-size: 1em; // Reduce el tamaño de la fuente para móviles
  }

  p {
    margin-left: 10px; // Reduce los márgenes laterales
    margin-right: 10px;
    text-align: justify;
  }
}


