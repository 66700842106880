.pageNotFound {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .mainContentContainer {
    margin-bottom: 20px;

    .numbersText {
      font-size: 100px;
      font-weight: 600;
      line-height: 1;
    }

    .pageNotFoundText {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .returnLink {
    text-decoration: none;

    padding: 8px 10px;
    border-radius: 3px;
  }
}
