
.page-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px; /* Agregamos padding para espaciar el contenido */
    display: flex; /* Convertimos el contenedor en un flexbox */
    flex-direction: column; /* El contenido se apila verticalmente */
    align-items: center; /* Centramos el contenido horizontalmente */
  }
  
  .img-wrapper {
    width: 90%; /* Ancho del contenedor de la imagen */
    margin-bottom: 20px; /* Espacio entre la imagen y el texto */
  }
  
  .img-wrapper img {
    width: 100%; /* La imagen ocupa todo el ancho del contenedor */
    height: auto; /* Para mantener la relación de aspecto */
  }
  
  .text-wrapper {
    width: 90%; /* Ancho del contenedor del texto */
    text-align: justify; /* Justificamos el texto */
  }
  
  .text-wrapper h1 {
    margin-top: 0; /* Eliminamos el margen superior del título */
    text-align: center; /* Centramos el título */
    font-family: Arial, sans-serif; /* Cambiamos la fuente del título */
  }
   