 // Estilos para la primera sección
 .section-container {
     text-align: center;

     img {
       max-width: 100%;
       height: auto;
       margin-bottom: 20px;
     }

     p {
       font-size: 20px;
       line-height: 1.5;
     }
   }

   // Estilos para la segunda sección (cuatro secciones de imagen y texto en gris)
   .gray-section {
     background-color: #f5f5f5;
     padding: 50px 0;

     .section-container {
       max-width: 1200px;
       margin: 0 auto;

       h2 {
         text-align: center;
         margin-bottom: 30px;
       }

       .grid-container {
         display: grid;
         grid-template-columns: repeat(4, 1fr);
         gap: 30px;

         .grid-item {
           background-color: #384E21;
           padding: 20px;
           border-radius: 5px;
           box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

           img {
             max-width: 100%;
             height: 200px;
             margin-bottom: 10px;
           }

           p {
             font-size: 14px;
             line-height: 1.5;
           }
         }
       }
     }
   }

   .gray-section-two {
    background-color: #f5f5f5;
    padding: 50px 0;

    .section-container {
      max-width: 1200px;
      margin: 0 auto;

      h2 {
        text-align: center;
        margin-bottom: 30px;
      }
       
      .grid-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;

        .grid-item {
          background-color: #3c721c;
          padding: 10px;
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

          img {
            max-width: 100%;
            height: 300px;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
            line-height: 1.5;
          }
        }
      }
    }
  }

   .section-container {
     max-width: 1200px;
     margin: 0 auto;
    
     h2 {
       text-align: center;
       margin-bottom: 30px;
     }

     .grid-container {
       display: grid;
       grid-template-columns: repeat(2, 1fr);
       gap: 30px;
        
       .grid-item-blog {
         background-color: white;
         padding: 20px;
         border-radius: 5px;
         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

         h3 {
           font-size: 18px;
           margin-bottom: 10px;
         }

         p {
           font-size: 14px;
           line-height: 1.5;
         }
       }
       .grid-item-contacto a{
        color: #384E21;
       }
       .image-maps {
        text-align: center; /* Centra el contenido horizontalmente */
      }
      
      .image-maps img {
        max-width: 350px; /* Tamaño máximo para la imagen */
        display: block; /* Para que la imagen esté centrada */
        margin: 0 auto; 
       padding-bottom: 20px;
      }
      
      .image-maps a {
        display: block; /* Para que el enlace esté en una línea separada */
        margin-top: 10px; /* Espacio superior entre la imagen y el enlace */
      }
      
    }
  } 
   // Estilos para tabletas
@media (max-width: 992px) {
  .gray-section .section-container .grid-container,
  .gray-section-two .section-container .grid-container {
    grid-template-columns: repeat(2, 1fr); // Cambia a dos columnas
    gap: 20px;

    .grid-item {
      padding: 10px; // Reduce el padding del marco
    }

    img {
      max-width: 90%; // Reduce un poco el tamaño máximo de las imágenes
      height: 250px; // Ajusta la altura de las imágenes
      margin: 10px auto; // Centra la imagen dentro del marco y ajusta el margen inferior
    }

    h2, h3 {
      font-size: 1.5em; // Ajusta el tamaño de los títulos
    }

    p {
      font-size: 14px; // Reduce el tamaño de la fuente del texto
      line-height: 1.5; // Mantiene la altura de línea
    }
  }

  .section-container .grid-container {
    grid-template-columns: 1fr; // Cambia a una columna
  }
}

// Estilos para móviles
@media (max-width: 768px) {
  .gray-section .section-container .grid-container .grid-item,
  .gray-section-two .section-container .grid-container .grid-item {
    padding: 2px; // Reduce el padding del marco
    border-radius: 5px; // Mantiene el radio de borde
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); // Ajusta la sombra para un marco más pequeño
  }

  .gray-section .section-container .grid-container .grid-item img,
  .gray-section-two .section-container .grid-container .grid-item img {
    max-width: 90%; // Reduce un poco el tamaño máximo de las imágenes
    height: 210px; // Ajusta la altura de las imágenes
    margin: 10px auto; // Centra la imagen dentro del marco y ajusta el margen inferior
  }

  .gray-section .section-container .grid-container .grid-item p,
  .gray-section-two .section-container .grid-container .grid-item p {
   
    font-size: 12px; // Reduce el tamaño de la fuente del texto dentro del marco
    line-height: 1.4; // Ajusta la altura de línea
  }

  .gray-section .section-container h2,
  .gray-section-two .section-container h2,
  .section-container h2 {
    font-size: 1.25em; // Reduce el tamaño del título principal
  }

  .gray-section .section-container h3,
  .gray-section-two .section-container h3,
  .section-container h3 {
    font-size: 1.1em; // Reduce el tamaño de los subtítulos
  }

  .section-container,
  .gray-section .section-container,
  .gray-section-two .section-container {
    padding: 10px 0; // Reduce el padding general
  }

  .section-container img {
    max-width: 80%;
    height: auto;
    margin: 0 20px 15px; // Ajusta el margen lateral y el margen inferior
  }
}

