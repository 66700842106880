
.image-center {
    text-align: center;
    margin: 20px 0;
  }
  
  .image-center img {
    max-width: 100%;
    height: auto;
  }
  
  .logo-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .logo-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
    transition: transform 0.3s ease-in-out;
  }
  
  .logo:hover {
    transform: scale(1.5);
  }
  
  .grid {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  
  .grid-item {
    width: 100%;
    max-width: 800px;
  }
  
  .player {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
  
@media (max-width: 992px) {
  .logo-section {
    grid-template-columns: repeat(3, 1fr); /* 3 columnas en pantallas más pequeñas también */
  }

  .logo {
    width: 90px;
    height: 90px;
  }

  .grid-item {
    max-width: 600px;
  }

  .image-center img {
    max-width: 90%;
  }

  .text-wrapper {
    padding: 0 20px;
  }

  .text-wrapper h2 {
    font-size: 1.8em;
  }

  .text-wrapper p {
    font-size: 1em;
    margin-left: 0;
    margin-right: 0;
    text-align: center;
  }
}

/* Estilos para móviles */
@media (max-width: 600px) {
  .logo {
    width: 80px;
    height: 80px;
  }

  .logo-section {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); 
    gap: 5px;
  }

  .grid-item {
    max-width: 100%;
  }

  .player {
    aspect-ratio: 4/3;
  }

  .image-center img {
    max-width: 100%;
  }

  .text-wrapper {
    padding: 0 10px;
  }

  .text-wrapper h2 {
    font-size: 1.5em;
  }

  .text-wrapper p {
    font-size: 0.9em;
    margin-left: 0;
    margin-right: 0;
    text-align: justify;
  }
}
