$sidebarWidth: 220px;
$openSidebarAnimation: 0.4s;
$closeSidebarAnimation: 0.2s;

.navbar {
  display: flex;

  top: 0;
  left: 0;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  z-index: 100;

  .logoAndWebsiteName {
    display: flex;
    align-items: center;

    text-decoration: none;

    .websiteNameContainer {
      max-width: 200px;
      margin-left: 10px;
    }
  }

  .navbarLinksContainer {
    display: flex;
    padding: 10px;

    .pageLink {
      text-decoration: none;

      &:hover {
        transition: 130ms;
      }
    }
  }

  .sidebarContainer {
    position: fixed;
    z-index: 100;

    .background {
      position: fixed;
      top: 0;
      left: 0;

      z-index: 100;

      background-color: rgba(0, 0, 0, 0.4);

      height: 100vh;
      width: 100vw;

      backdrop-filter: blur(2px);
    }

    .sidebar {
      position: fixed;
      top: 0;
      right: 0;

      display: flex;
      flex-direction: column;

      z-index: 101;

      background-color: rgb(255, 255, 255);
      color: black;
      box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.25);

      height: 100%;
      width: $sidebarWidth;

      .sidebarTitleContainer {
        text-align: center;
        font-size: 20px;
        border-bottom: 1px solid grey;
      }

      .sidebarLinksContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 10px;

        .pageLink {
          text-align: center;

          color: black;
          text-decoration: none;

          width: $sidebarWidth - 30px;
          padding: 5px 5px;
          border-radius: 3px;

          margin-bottom: 1px;
        }
      }
    }

    &.sidebarContainer__active {
      .background {
        @keyframes BackgroundFadeIn {
          0% {
            background-color: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0px);
          }

          100% {
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2px);
          }
        }

        animation-name: BackgroundFadeIn;

        animation-duration: $openSidebarAnimation;
        animation-iteration-count: 1;
      }

      .sidebar {
        @keyframes SidebarAppear {
          0% {
            right: -$sidebarWidth;
          }

          100% {
            right: 0;
          }
        }

        animation-name: SidebarAppear;

        animation-duration: $openSidebarAnimation;
        animation-iteration-count: 1;
      }
    }

    &.sidebarContainer__disabled {
      .background {
        display: none;

        @keyframes BackgroundFadeOut {
          0% {
            display: inline;
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2px);
          }

          100% {
            display: none;
            background-color: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0px);
          }
        }

        animation-name: BackgroundFadeOut;

        animation-duration: $closeSidebarAnimation;
        animation-iteration-count: 1;
      }

      .sidebar {
        right: -$sidebarWidth;

        @keyframes SidebarDisappear {
          0% {
            right: 0;
          }

          100% {
            right: -$sidebarWidth;
          }
        }

        animation-name: SidebarDisappear;

        animation-duration: $closeSidebarAnimation;
        animation-iteration-count: 1;
      }
    }

    &.transparentWhenPageLoads {
      @keyframes TransparentWhenPageLoads {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 0;
        }
      }

      opacity: 1;

      animation-name: TransparentWhenPageLoads;

      animation-duration: $closeSidebarAnimation;
      animation-iteration-count: 1;
    }
  }
}

.navbar__horizontal {
  display: flex;
  align-items: center;

  .hamburgerContainer {
    display: none;
  }
}

.navbar__vertical,
.navbar__mobile__vertical {
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logoAndWebsiteName {
    flex-direction: column;
    justify-content: center;
  }

  .hamburgerContainer {
    display: none;
  }

  .navbarLinksContainer {
    display: flex;
    justify-content: center;
    padding: 10px;

    .pageLink {
      padding: 4px 10px;

      text-decoration: none;

      &:hover {
        transition: 130ms;
      }
    }
  }
}

.navbar__mobile__hamburger {
  display: flex;
  align-items: center;

  .hamburgerContainer {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbarLinksContainer {
    display: none;
  }
}
