
.carousel-wrapper {
    margin: 50px auto;
    max-width: 500px; /* Ajusta el ancho del carrusel */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra alrededor del carrusel */
    padding: 10px; /* Espacio alrededor del contenido del carrusel */
    background-color: #384E21; /* Fondo blanco para el carrusel */
    border-radius: 10px; /* Bordes redondeados */
  }
  
  /* Estilo para las diapositivas */
  .carousel .slide {
    background-color: #B4AA99;
    border: 1px solid #4E4637;
    padding: 5px; /* Añade un pequeño espacio alrededor de cada diapositiva */
    border-radius: 10px; /* Bordes redondeados para las diapositivas */
    overflow: hidden; /* Asegura que el contenido no se salga del cuadro */
    display: flex;
    justify-content: center; /* Centra el contenido horizontalmente */
    align-items: center; /* Centra el contenido verticalmente */
  }
  
  /* Estilo para las imágenes en las diapositivas */
  .carousel .slide img {
    max-width: 100%; /* Hace que las imágenes sean responsivas */
    max-height: 400px; /* Ajusta la altura máxima de las imágenes */
    height: auto; /* Mantiene la proporción de las imágenes */
    display: block;
  }
  
  /* Estilo para los textos descriptivos */
  .carousel .legend {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 10px;
    text-align: center;
    font-size: 18px;
  }
  


