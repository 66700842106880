.heroSection {
  .imageContainer {
    img {
      object-fit: cover;
    }
  }

  .linkButtonCotnainer {
    display: flex;

    .linkButton {
      text-align: center;
      text-decoration: none;
    }

    .linkBtn__imageContainer {
      .linkBtn__image {
        object-fit: cover;
      }
    }
  }
}

.heroSection__textOnImage {
  position: relative;

  width: stretch;
  height: 55vh;

  .imageContainer {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .infoContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    width: 100%;

    color: white;

    .titleText {
      font-size: calc(20px + 2vw);
      font-weight: 600;
      text-shadow: -1px 1px 4px rgba(0, 0, 0, 1);
    }

    .subtitleText {
      font-size: calc(10px + 1vw);
      font-weight: 600;

      width: 90%;
      max-width: 700px;
      text-shadow: -1px 1px 4px rgba(0, 0, 0, 1);
    }
  }
}

.heroSection__desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .imageContainer {
    height: 100%;
    width: 50%;
    padding-right: 5%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .infoContainer {
    width: 60%;

    .subtitleText {
      width: 100%;
      max-width: 500px;
    }
  }
}

.heroSection__mobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  aspect-ratio: 3/2.8;

  .imageContainer {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
    width: 100%;

    .subtitleText {
      font-size: calc(14px + 1vw);
      font-weight: 600;

      width: 90%;
      max-width: 500px;
    }

    .linkButtonCotnainer {
      display: flex;
      justify-content: center !important;

      .linkButton {
        color: white;
        text-align: center;
      }
    }
  }
}
