.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  background-color: white;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.2);

  padding: 10px 0px;

  .allSocialMediasContainer {
    display: flex;
    gap: 50px;

    color: black;

    font-size: 30px;
    text-decoration: none;

    margin-top: 30px;
  }

  .copyrightSection {
    margin-top: 20px;

    .createdBySection {
      font-size: 14px;
    }
  }
}
